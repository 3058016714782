export const delegateEvent = (element, eventType, selector, listener, options) => {
    element.addEventListener(
        eventType,
        /**
         * @param {Event} event
         */
        function callback(event) {
            if (event.target instanceof HTMLElement || event.target instanceof SVGElement) {
                var closest = event.target.closest(selector);
                if (closest) {
                    event.closest = closest;
                    listener(event);
                }
            }
        },
        options || null
    );
};
