export const initDeckPlanFilter = () => {
    const deckSelect = document.querySelector('[data-deck-filter]');

    if (!deckSelect) {
        return;
    }

    deckSelect.addEventListener('change', e => {
        filterDecks(e.target.value);
    });

    filterDecks(deckSelect.value);
};

const filterDecks = filterVal => {
    const decks = document.querySelectorAll('[data-decks]');

    decks.forEach(deck => {
        deck.classList.remove('deck-plans__deck--active');

        if (deck.id == filterVal) {
            deck.classList.add('deck-plans__deck--active');
        }
    });
};
