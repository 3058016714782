import { delegateEvent } from './delegate';
import { initDeckPlanFilter } from '../features/deck-filter';

export const initOverlayByUrl = () => {
    const overlayButtons = document.querySelectorAll('[data-overlay-url]');

    overlayButtons?.forEach(button => {
        button.addEventListener('click', e => {
            e.preventDefault();

            const url = button.getAttribute('data-overlay-url');

            Overlay.load(url, {
                modifiers: ['extra-wide'],
                wait_for_scripts: true,
                callback: () => {
                    closeOverlay();

                    // This is called for every overlay
                    // It early returns but should probably not do this
                    initDeckPlanFilter();
                },
            });
        });
    });
};

export const initOverlayFormSubmissions = () => {
    delegateEvent(document, 'submit', 'form[data-overlay]', event => {
        event.preventDefault();

        const formNode = event.target.closest('form');
        const formData = new FormData(formNode);

        const config = {
            data: formData,
            method: 'POST',
            modifiers: ['extra-wide'],
            wait_for_scripts: true,
            callback: () => {
                closeOverlay();
            },
        };

        Overlay.load(formNode.getAttribute('action'), config);

        formNode.reset();
    });
};

const closeOverlay = () => {
    const overlayCloseButtons = document.querySelectorAll(
        '[data-close-overlay]',
    );

    overlayCloseButtons.forEach(button => {
        button.addEventListener('click', () => {
            Overlay.close();
        });
    });
};
